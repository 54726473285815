import React, { useState, useCallback, useEffect, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useDarkMode from 'use-dark-mode';

import { useSnackbar } from 'notistack';
import update from 'immutability-helper';

import { Button, Grid, Link, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { ThemeCircularProgress, ThemeLoader } from 'src/theming';

import { Card, generateDivisions } from './generate-divisions';

import { useStyle } from './division-section-styles/division-section-style';

import { useAuth } from 'src/user-management/context-auth';
import { useGlobalFilter, useDashboardContext } from 'src/shared/contexts';

import { Division } from './division-card';
import { contractsDashboardPrepareDivisionsForSaving } from './division-prepare-divisions-for-saving';
import { uniq } from 'lodash';

interface DivisionsProps {
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    activeDivision?: string;
    handleOnClickCard: (division: string) => void;
}

export const DivisionsSection = (props: DivisionsProps): JSX.Element => {
    const { editMode, setEditMode, activeDivision, handleOnClickCard } = props;
    const darkMode = useDarkMode();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { userData, updateUserSettings } = useAuth();
    const {
        filter,
        availableDivisions,
        isReceivingContractsAmountsLoading,
        contractsAmounts,
    } = useGlobalFilter();
    const {
        handleEditMode,
        handleCancelEditMode,
        handleSaveDivisionSettings,
        handleCloseEditMode,
    } = useDashboardContext();

    const classes = useStyle({ editMode, isDarkMode: darkMode.value });

    const [showAll, setShowAll] = useState(false);
    const [visibleCards, setVisibleCards] = useState(new Set([]));
    const [defaultDivision, setDefaultDivision] = useState(userData.defaultDivision);
    const [cards, setCards] = useState([]);
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [positions, setPositions] = useState([]);

    useEffect(() => {
        if (!activeDivision && Array.isArray(cards) && cards.length > 0) {
            const sortedAvailableDivisions: Card[] = cards.filter((card: Card) =>
                availableDivisions.map(({ division }) => division).includes(card.key)
            );
            if (
                Array.isArray(sortedAvailableDivisions) &&
                sortedAvailableDivisions.length > 0
            ) {
                handleOnClickCard(sortedAvailableDivisions[0].key);
            }
        }
    }, [activeDivision, cards]);

    useEffect(() => {
        if (Array.isArray(userData.divisions)) {
            setVisibleCards(
                new Set(
                    userData.divisions
                        .filter((item) => item.favourite)
                        .map((item) => item.value)
                )
            );
            setPositions(
                userData.divisions.map((item) => ({
                    division: item.value,
                    position: item.position,
                }))
            );
        }
    }, [userData.divisions]);

    useEffect(() => {
        if (Array.isArray(positions) && positions.length > 0) {
            if (showAll || editMode) {
                setCards(
                    generateDivisions(t, userData.divisions, positions).map(
                        (card: Card) => {
                            return {
                                ...card,
                                isShow: true,
                            };
                        }
                    )
                );
            } else {
                setCards(
                    generateDivisions(t, userData.divisions, positions).map(
                        (card: Card) => {
                            return {
                                ...card,
                                isShow: visibleCards.has(card.key),
                            };
                        }
                    )
                );
            }
        }
    }, [showAll, editMode, visibleCards, positions, userData.divisions]);

    const toggleShowAll = (): void => {
        setShowAll(!showAll);
    };

    const toggleCardVisibility = (e: MouseEvent<HTMLElement>, key: string): void => {
        e.stopPropagation();
        const newVisibleCards = new Set(visibleCards);
        if (visibleCards.has(key)) {
            newVisibleCards.delete(key);
        } else {
            newVisibleCards.add(key);
        }
        setVisibleCards(newVisibleCards);
    };

    const handleSave = (): void => {
        handleSaveDivisionSettings();

        const formattedDivisions = contractsDashboardPrepareDivisionsForSaving(
            userData.divisions,
            visibleCards,
            cards
        );
        setSaveLoading(true);
        updateUserSettings({
            clientConnections: filter.customerConnections,
            clients: filter.customers,
            darkMode: userData.darkTheme,
            defaultDivision: defaultDivision,
            divisions: formattedDivisions,
            favouriteDatabase: userData.favouriteDatabase,
            currentDatabase: userData.currentDatabase,
        })
            .then(() => {
                const timer = setTimeout(() => {
                    setEditMode(false);
                    handleCloseEditMode();
                    enqueueSnackbar(t('contract-types:savedSuccessfully'), {
                        variant: 'success',
                    });
                    clearTimeout(timer);
                }, 500);
            })
            .catch(() => {
                enqueueSnackbar(t('errors:unknownError'), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setSaveLoading(false);
            });
    };

    const openEditMode = (): void => {
        setEditMode(true);
        handleEditMode();
    };

    const closeEditMode = (): void => {
        handleCancelEditMode();
        handleCloseEditMode();
        setEditMode(false);
        setDefaultDivision(userData.defaultDivision);
        setVisibleCards(
            new Set(
                userData.divisions
                    .filter((item) => item.favourite)
                    .map((item) => item.value)
            )
        );
    };

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = cards[dragIndex];
            const newCards = update(cards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            });
            setCards(newCards);
            setPositions(
                newCards.map((item, index) => ({ division: item.key, position: index }))
            );
        },
        [cards]
    );

    const divisions = useMemo(
        () => uniq(availableDivisions.map(({ division }) => division)),
        [availableDivisions]
    );

    console.log(cards);

    return (
        <Grid item className={classes.divisionsContainer}>
            <Grid container className={classes.editModeContainer}>
                <Typography variant='h5'>{t('contract-types:branches')}</Typography>
                <Grid item container className={classes.editModeButtons}>
                    {!editMode ? (
                        <Button startIcon={<EditIcon />} onClick={openEditMode}>
                            {t('common:edit')}
                        </Button>
                    ) : (
                        <>
                            <Button
                                data-mode='edit'
                                disabled={isSaveLoading}
                                startIcon={
                                    <>
                                        {!isSaveLoading && <SaveIcon />}
                                        <ThemeCircularProgress
                                            isLoading={isSaveLoading}
                                        />
                                    </>
                                }
                                onClick={handleSave}
                                className={classes.saveButton}
                            >
                                {t('common:save')}
                            </Button>
                            <Button
                                data-mode='edit'
                                disabled={isSaveLoading}
                                onClick={closeEditMode}
                            >
                                {t('common:cancel')}
                            </Button>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid item container className={classes.contractTypes}>
                {isReceivingContractsAmountsLoading && <ThemeLoader />}
                {!isReceivingContractsAmountsLoading &&
                    cards.map((card: Card, index: number) => {
                        const contractsAmount = contractsAmounts.find(
                            (item) => item.division === card.key
                        )?.amount;
                        const showCard = divisions.includes(card.key) && contractsAmount;

                        return showCard ? (
                            <Division
                                key={card.key}
                                index={index}
                                editMode={editMode}
                                card={card}
                                handleOnClickCard={handleOnClickCard}
                                toggleCardVisibility={toggleCardVisibility}
                                visibleCards={visibleCards}
                                moveCard={moveCard}
                                defaultDivision={defaultDivision}
                                setDefaultDivision={setDefaultDivision}
                                activeDivision={activeDivision}
                                contractsAmount={contractsAmount}
                                isReceivingContractsAmountsLoading={
                                    isReceivingContractsAmountsLoading
                                }
                            />
                        ) : null;
                    })}
            </Grid>
            {!editMode &&
                generateDivisions(t, userData.divisions, positions).length !==
                    visibleCards.size && (
                    <Link className={classes.showMoreLink} onClick={toggleShowAll}>
                        {showAll
                            ? t('contract-types:hide')
                            : t('contract-types:showMore')}
                    </Link>
                )}
        </Grid>
    );
};
